import { Grid, Tooltip } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

function GridAlbum({
    name, artist, playcount, cover, url, selectedAlbum = '', setSelectedAlbum,
}: {
    name: string, artist: string, playcount: string, cover: string, url: string,
    selectedAlbum?: string, setSelectedAlbum?: Dispatch<SetStateAction<string>>,
}) {
    return (
        <Tooltip
            key={url}
            title={`${artist} - ${name} (${playcount} plays)`}
            arrow
            placement="bottom"
        >
            <Grid
                item
                xs={1}
                onMouseEnter={() => setSelectedAlbum && setSelectedAlbum(url)}
                onMouseLeave={() => setSelectedAlbum && setSelectedAlbum('')}
                sx={{
                    opacity: selectedAlbum !== '' && selectedAlbum !== url
                        ? '65%' : '100%',
                    transition: '0.2s opacity',
                }}
            >
                <a href={url} target="_blank" rel="noreferrer">
                    <img
                        src={cover}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        alt={`${artist} - ${name}`}
                    />
                </a>
            </Grid>
        </Tooltip>
    );
}

export default GridAlbum;
