import { Grid as MGrid } from '@mui/material';
import { useState } from 'react';

import GridAlbum from '../components/GridAlbum';

function Grid({ albums, columns = 10 }: { albums: Album[]; columns?: number; }) {
    const [selectedAlbum, setSelectedAlbum] = useState('');

    return (
        <MGrid
            sx={{ maxHeight: '100%' }}
            container
            columns={{
                xs: Math.round(columns / 2.5),
                md: Math.round(columns / 1.5),
                xl: columns,
            }}
        >
            {albums.map((album: Album) => (
                <GridAlbum
                    name={album.name}
                    artist={album.artist}
                    playcount={album.playcount}
                    cover={album.image}
                    url={album.url}
                    selectedAlbum={selectedAlbum}
                    setSelectedAlbum={setSelectedAlbum}
                    key={album.url}
                />
            ))}
        </MGrid>
    );
}

export default Grid;
