/* eslint-disable no-param-reassign */

function getRGBString(rgb: number[]) {
    return (`rgb(${rgb[0]},${rgb[1]},${rgb[2]})`);
}

function rgbToLab(r: number, g: number, b: number) {
    r /= 255; g /= 255; b /= 255;
    let x; let y; let z;

    r = (r > 0.04045) ? ((r + 0.055) / 1.055) ** 2.4 : r / 12.92;
    g = (g > 0.04045) ? ((g + 0.055) / 1.055) ** 2.4 : g / 12.92;
    b = (b > 0.04045) ? ((b + 0.055) / 1.055) ** 2.4 : b / 12.92;

    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

    x = (x > 0.008856) ? x ** (1 / 3) : (7.787 * x) + 16 / 116;
    y = (y > 0.008856) ? y ** (1 / 3) : (7.787 * y) + 16 / 116;
    z = (z > 0.008856) ? z ** (1 / 3) : (7.787 * z) + 16 / 116;

    return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)];
}

function deltaE(labA: number[], labB: number[]) {
    const deltaL = labA[0] - labB[0];
    const deltaA = labA[1] - labB[1];
    const deltaB = labA[2] - labB[2];
    const c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
    const c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
    const deltaC = c1 - c2;
    let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
    deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
    const sc = 1.0 + 0.045 * c1;
    const sh = 1.0 + 0.015 * c1;
    const deltaLKlsl = deltaL / (1.0);
    const deltaCkcsc = deltaC / (sc);
    const deltaHkhsh = deltaH / (sh);
    const i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
    return i < 0 ? 0 : Math.sqrt(i);
}

function colourDiffLab(colour1: number[], colour2: number[]) {
    const lab1 = rgbToLab(colour1[0], colour1[1], colour1[2]);
    const lab2 = rgbToLab(colour2[0], colour2[1], colour2[2]);
    return deltaE(lab1, lab2) / 100;
}

function groupSimilarColours(colours: { album: Album, colour: number[] }[], threshold: number) {
    const reducedColours: { [key: string]: Album[] } = {};

    let availableColours = colours.slice();
    while (availableColours.length > 0) {
        const colour = availableColours[0];
        reducedColours[getRGBString(colour.colour)] = [colour.album];
        availableColours.forEach((otherColour) => {
            if (colour === otherColour) return;
            const diff = colourDiffLab(colour.colour, otherColour.colour);
            if (diff < threshold) {
                reducedColours[getRGBString(colour.colour)].push(otherColour.album);
            }
        });
        availableColours = availableColours.filter(
            (x) => !reducedColours[getRGBString(colour.colour)].includes(x.album),
        );
    }

    return reducedColours;
}

export default groupSimilarColours;
