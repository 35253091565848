import {
    Box, Button, Divider, Drawer, InputAdornment, List, ListItem, TextField,
    Typography, FormControl, InputLabel, Select, MenuItem, Slider, Collapse,
} from '@mui/material';
import { IoMdColorPalette, IoMdGrid, IoMdMenu } from 'react-icons/io';
import { MdOutlineAnimation } from 'react-icons/md';
import { ImLastfm } from 'react-icons/im';
import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import ListItemLink from './components/ListItemLink';
import Grid from './pages/Grid';
import Colour from './pages/Colour';

const drawerWidth = 240;

function App() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [colourThreshold, setColourThreshold] = useState(0.3);
    const [usernameInput, setUsernameInput] = useState(localStorage.getItem('username') || '');
    const [inputError, setInputError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [timePeriod, setTimePeriod] = useState('overall');
    const [albums, setAlbums] = useState<Album[]>([]);
    const location = useLocation();
    const container = window !== undefined ? () => window.document.body : undefined;

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (usernameInput.length > 0) {
                localStorage.setItem('username', usernameInput);
                fetch('https://ws.audioscrobbler.com/2.0/?method=user.gettopalbums&user='
                    + `${usernameInput}&period=${timePeriod}&api_key=`
                    + `${process.env.REACT_APP_LASTFM_KEY}&format=json`)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            setInputError(true);
                            setErrorMessage(data.message);
                        } else if (!data.topalbums) {
                            setInputError(true);
                            setErrorMessage('Something went wrong...');
                        } else {
                            setInputError(false);
                            setErrorMessage('');
                            setAlbums(data.topalbums.album.map((album: LastFMAlbum) => ({
                                name: album.name,
                                artist: album.artist.name,
                                playcount: album.playcount,
                                image: album.image[album.image.length - 1]['#text'],
                                url: album.url,
                            })));
                        }
                    });
            }
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [usernameInput, timePeriod]);

    const drawer = (
        <div>
            <List>
                <ListItem>
                    <Typography variant="h5" sx={{ fontWeight: 500, pb: 1 }}>
                        covor
                    </Typography>
                </ListItem>
                <Divider />
                <ListItemLink location={location} Icon={IoMdGrid} text="Grid" to="/" />
                <ListItemLink
                    location={location}
                    Icon={IoMdColorPalette}
                    text="Colour"
                    to="/colour"
                />
                <Collapse in={location.pathname === '/colour'}>
                    <Box sx={{ ml: 2, py: 1, width: '86%' }}>
                        <Typography variant="overline">
                            Threshold
                        </Typography>
                        <Slider
                            size="small"
                            value={colourThreshold}
                            onChange={(e, v) => setColourThreshold(v as number)}
                            step={0.05}
                            min={0.1}
                            max={0.5}
                            aria-label="Threshold"
                            valueLabelDisplay="auto"
                            track={false}
                        />
                    </Box>
                </Collapse>
                <ListItemLink
                    location={location}
                    Icon={MdOutlineAnimation}
                    text="Animated"
                    secondary={albums.length > 0
                        ? `(${albums.filter((album) => album.image.endsWith('gif')).length})` : ''}
                    to="/animated"
                />
                <Divider />
                <ListItem sx={{ pt: 2 }}>
                    <TextField
                        placeholder="last.fm username"
                        error={inputError}
                        helperText={errorMessage}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ImLastfm />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        value={usernameInput}
                        onChange={(e) => setUsernameInput(e.target.value)}
                    />
                </ListItem>
                <ListItem>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="time-period">Time period</InputLabel>
                        <Select
                            labelId="time-period"
                            value={timePeriod}
                            onChange={(e) => setTimePeriod(e.target.value)}
                            label="Time period"
                        >
                            <MenuItem value="overall">Overall</MenuItem>
                            <MenuItem value="7day">7 days</MenuItem>
                            <MenuItem value="1month">1 month</MenuItem>
                            <MenuItem value="3month">3 months</MenuItem>
                            <MenuItem value="6month">6 months</MenuItem>
                            <MenuItem value="12month">12 months</MenuItem>
                        </Select>
                    </FormControl>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="Navigation menu"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={() => setMobileOpen(!mobileOpen)}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Button
                    variant="contained"
                    aria-label="Open menu"
                    onClick={() => setMobileOpen(!mobileOpen)}
                    sx={{
                        position: 'fixed', right: 10, top: 10, display: { sm: 'none' },
                    }}
                >
                    <IoMdMenu size={22} />
                </Button>
                <Routes>
                    <Route path="/" element={<Grid albums={albums} />} />
                    <Route
                        path="colour"
                        element={<Colour albums={albums} threshold={colourThreshold} />}
                    />
                    <Route
                        path="animated"
                        element={(
                            <Grid
                                albums={albums.filter((album) => album.image.endsWith('gif'))}
                                columns={4}
                            />
                        )}
                    />
                </Routes>
            </Box>
        </Box>
    );
}

export default App;
