import { Grid } from '@mui/material';
import FastAverageColor from 'fast-average-color';
import { useEffect, useState } from 'react';

import GridAlbum from '../components/GridAlbum';
import groupSimilarColours from '../helpers/colour';

const fac = new FastAverageColor();

function Colour({ albums, threshold }: { albums: Album[]; threshold: number }) {
    const [averageColours, setAverageColours] = useState<{ [key: string]: Album[] }>({});
    const [selectedAlbum, setSelectedAlbum] = useState('');

    useEffect(() => {
        (async () => Promise.all(
            albums.map((album) => fac.getColorAsync(album.image, {
                algorithm: 'dominant', mode: 'speed',
            }).then((result) => ({
                album,
                colour: result.value.slice(0, 3),
            }))),
        ))().then((data) => {
            setAverageColours(groupSimilarColours(data, threshold));
        });
    }, [albums, threshold]);

    return (
        <Grid sx={{ height: '100%' }} container columns={3}>
            {Object.keys(averageColours).map((group) => (
                <Grid
                    item
                    xs={1}
                    sx={{ backgroundColor: group }}
                    key={group}
                >
                    <Grid sx={{ maxHeight: '100%' }} container columns={5}>
                        {averageColours[group].map((album: Album) => (
                            <GridAlbum
                                name={album.name}
                                artist={album.artist}
                                playcount={album.playcount}
                                cover={album.image}
                                url={album.url}
                                selectedAlbum={selectedAlbum}
                                setSelectedAlbum={setSelectedAlbum}
                                key={album.url}
                            />
                        ))}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

export default Colour;
