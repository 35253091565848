import {
    ListItem, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import { IconType } from 'react-icons';
import { forwardRef, useMemo } from 'react';
import { NavLinkProps, NavLink, Location } from 'react-router-dom';

function ListItemLink({
    Icon, text, secondary, to, location,
}: { Icon: IconType, text: string, secondary?: string; to: string, location: Location }) {
    const renderLink = useMemo(
        () => forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'>>((
            itemProps,
            ref,
            // eslint-disable-next-line react/jsx-props-no-spreading
        ) => <NavLink to={to} ref={ref} {...itemProps} role={undefined} />),
        [to],
    );

    return (
        <ListItem button selected={location.pathname === to} component={renderLink}>
            <ListItemIcon>
                <Icon size={22} />
            </ListItemIcon>
            <ListItemText primary={(
                <>
                    {text}
                    &nbsp;
                    <Typography
                        color="text.secondary"
                        component="span"
                        gutterBottom={false}
                    >
                        {secondary || ''}

                    </Typography>
                </>
            )}
            />
        </ListItem>
    );
}

export default ListItemLink;
